<template>
    <div class="h-100">
        <b-card
            @click="lightboxToggler = !lightboxToggler"
            class="artwork-thumbnail-card d-flex h-100"
            body-class="d-flex flex-column"
        >
            <b-card-title>{{ artwork.name }}</b-card-title>
            <b-card-img-lazy
                :src="artwork.thumbnail"
                :alt="artwork.name"
                class="mt-auto mb-auto"
            />
            <b-card-sub-title class="mt-2">{{ displayDate }}</b-card-sub-title>
        </b-card>

        <FsLightbox
            :toggler="lightboxToggler"
            :sources="lightboxSources"
            :captions="lightboxCaptions"
            :types="lightboxTypes"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import FsLightbox from "fslightbox-vue";
    import LightboxCaption from "../LightboxCaption.vue";

    const OUTPUT_DATE_FORMAT = 'ddd, Do MMM YYYY'; // Sun, 21st Jun 2020.

    export default {
        components: { FsLightbox },
        props: {
            artwork: Object,
        },
        data() {
            return {
                lightboxToggler: false,
            };
        },
        computed: {
            artworkPieces() {
                return this.artwork.pieces;
            },
            displayDate() {
                return moment(
                    this.artwork.display_datetime,
                    moment.ISO_8601,
                    true,
                ).format(OUTPUT_DATE_FORMAT);
            },
            lightboxSources() {
                return this.artworkPieces.map((piece) => {
                    return piece.video_src || piece.image;
                });
            },
            lightboxCaptions() {
                const captions = [];
                this.artworkPieces.forEach((piece) => {
                    if (piece.caption) {
                        captions.push({
                            component: LightboxCaption,
                            props: { caption: piece.caption },
                        });
                    }
                });
                return captions;
            },
            lightboxTypes() {
                return this.artworkPieces.map((piece) => {
                    return piece.video_src ? "youtube" : "image";
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep .card-header {
        padding: 0;
    }

    .artwork-thumbnail-card:hover {
        cursor: pointer;
    }
</style>
