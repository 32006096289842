// Application component that renders all the View components.

<template>
    <router-view></router-view>
</template>

<script>
    export default {}
</script>

<style lang="scss">
    @import "./assets/scss/main.scss";
</style>
