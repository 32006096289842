// Main Vue application.

import Vue from 'vue/dist/vue.esm.js'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import _ from 'lodash'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import router from './routers'
import store from './store'
import './filters/filters'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
    el: '#app',
    router,
    store,
    components: {
        'app': App
    }
});
