// Module for Artwork Categories.

import axios from 'axios';

const state = {
    artworkCategories: [],
    filteredArtworkCategories: [],
};

const getters = {
    artworkCategories(state) {
        return state.artworkCategories;
    },
    filteredArtworkCategories(state) {
        return state.filteredArtworkCategories;
    },
    searchArtworkCategoriesByTitle: (state) => (title) => {
        if (title) {
            return state.artworkCategories.filter(function(category) {
                if (state.filteredArtworkCategories.includes(category)){
                    return false
                } else {
                    return category.title.toLowerCase().includes(title.toLowerCase())
                }
            })
        } else {
            return []
        }
    },
};

const actions = {
    getArtworkCategories({ commit }) {
        axios.get('/api/artwork/categories').then((response) => {
            commit('setArtworkCategories', response.data);
        });
    },
    addArtworkCategoryToFilter({ commit, state }, category) {
        if (!state.filteredArtworkCategories.includes(category)) {
            commit('addArtworkCategoryToFilter', category)
        }
    },
    removeArtworkCategoryFromFilter({ commit, state }, category) {
        if (state.filteredArtworkCategories.includes(category)) {
            commit('removeArtworkCategoryFromFilter', category)
        }
    },
};

const mutations = {
    setArtworkCategories(state, categories) {
        state.artworkCategories = categories
    },
    addArtworkCategoryToFilter(state, category) {
        state.filteredArtworkCategories.push(category)
    },
    removeArtworkCategoryFromFilter(state, category) {
        state.filteredArtworkCategories.splice(state.filteredArtworkCategories.indexOf(category), 1)
    },
};


export default {
    state,
    getters,
    actions,
    mutations
}
