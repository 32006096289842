// Module for CareerHistoryEvents.

import axios from 'axios'

// Properties available in the store for this Module.
const state = {
    careerEvents: []
};

// Getters.
const getters = {
    careerEvents(state) {
        return state.careerEvents
    },
    careerEventsByFilteredSkills: (state) => (skillTags) =>  {
        if (skillTags.length > 0) {
            // The filtered SkillTags list needs to get mapped to just the IDs.
            let skillTagIds = skillTags.map(tag => tag.id);

            // For each careerEvent, if there are some of the event's SkillTags
            // in the list of SkillTag IDs they will show in the results.
            return state.careerEvents.filter(
                careerEvent => careerEvent.event_skills.some(
                    skillId => skillTagIds.includes(skillId)
                )
            )
        } else {
            // Otherwise, just return all careerEvents.
            return state.careerEvents
        }
    }
};

// Actions.
const actions = {
    // Get all available CareerHistoryEvents.
    getCareerEvents({ commit }) {
        axios.get('/api/cv/career_history_events').then((response) => {
            commit('setCareerEvents', response.data)
        })
    }
};

// Mutations.
const mutations = {
    setCareerEvents(state, careerEvents) {
        state.careerEvents = careerEvents
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
