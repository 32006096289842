<template>
    <div id="active-artwork-categories">
        <CategoryTagSearchForm
            label="Search Categories to Filter Artwork pieces by"
            placeHolder="Filter Artwork"
            buttonText="Add Category"
        />
        <div>
            <ul class="tag-list" v-if="filteredArtworkCategories">
                <li v-for="categoryTag in filteredArtworkCategories">
                    <CategoryTag
                        :categoryTag="categoryTag"
                        :removable="true"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import CategoryTag from './CategoryTag';
    import CategoryTagSearchForm from './CategoryTagSearchForm';

    export default {
        components: {
            CategoryTag,
            CategoryTagSearchForm,
        },
        data() {
            return {
                searchString: '',
            };
        },
        computed: {
            ...mapGetters(['filteredArtworkCategories']),
        },
    };
</script>
