// Store for SkillTag objects.

import axios from 'axios'

// Initial state.
const state = {
    filteredSkillTags: [],
    skillTags: [],
    skillTypes: [],
};

// Getters.
const getters = {
    filteredSkillTags(state) {
        return state.filteredSkillTags
    },
    skillTags(state) {
        return state.skillTags
    },
    filteredSkillTagsByType: (state) => (type) => {
        return state.filteredSkillTags.filter(tag => tag.skill_type === type)
    },
    skillTagsByType: (state) => (type) => {
        return state.skillTags.filter(tag => tag.skill_type === type)
    },
    filteredSkillTagsByTypeCount: (state) => (type) => {
        return state.filteredSkillTags.filter(tag => tag.skill_type === type).length
    },
    skillTagsByTypeCount: (state) => (type) => {
        return state.skillTags.filter(tag => tag.skill_type === type).length
    },
    searchSkillTagsByTitle: (state) => (title) => {
        if (title) {
            return state.skillTags.filter(function(tag) {
                if (state.filteredSkillTags.includes(tag)){
                    return false
                } else {
                    return tag.title.toLowerCase().includes(title.toLowerCase())
                }
            })
        } else {
            return []
        }
    },
    skillTagTypes(state) {
        return state.skillTypes
    },
    skillTagById: (state) => (id) => {
        return state.skillTags.find(tag => tag.id === id)
    },
};

// Actions.
const actions = {
    // Get all available SkillTags.
    getSkillTags({ commit }) {
        axios.get('/api/cv/skill_tags').then((response) => {
            commit('setSkillTags', response.data)
        })
    },
    // Get the list of skill types that are available.
    getSkillTagTypes({ commit }) {
        axios.get('/api/cv/skill_tag_types').then((response) => {
            commit('setSkillTagTypes', response.data)
        })
    },
    addSkillTagToFilter({ commit, state }, skillTag) {
        if (!state.filteredSkillTags.includes(skillTag)) {
            commit('addSkillTagToFilter', skillTag)
        }
    },
    removeSkillTagFromFilter({ commit, state }, skillTag) {
        if (state.filteredSkillTags.includes(skillTag)) {
            commit('removeSkillTagFromFilter', skillTag)
        }
    }
};

// Mutations.
const mutations = {
    setSkillTags(state, skillTags) {
        state.skillTags = skillTags
    },
    setSkillTagTypes(state, skillTypes) {
        state.skillTypes = skillTypes
    },
    addSkillTagToFilter(state, skillTag) {
        state.filteredSkillTags.push(skillTag)
    },
    removeSkillTagFromFilter(state, skillTag) {
        state.filteredSkillTags.splice(state.filteredSkillTags.indexOf(skillTag), 1)
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
