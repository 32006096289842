<template>
    <div class="col">
        <div class="row">
            <label for="search-string-field" class="sr-only">{{ label }}</label>
            <input
                id="search-string-field"
                class="form-control mb-2 mr-sm-2"
                type="text"
                :placeholder="placeHolder"
                v-model="searchString"
            >
        </div>
        <div class="row">
            <ul class="horizontal-list" id="search-results">
                <SkillTag
                    v-for="skillTag in searchSkillTagsByTitle(searchString)"
                    :key="skillTag.id"
                    @skillTagAdded="skillTagAdded"
                    :class="'mx-1 mb-1'"
                    :actionsAllowed="true"
                    :skillTag="skillTag"
                />
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import SkillTag from './SkillTag'

    export default {
        data: function() {
            return {
                searchString: '',
            }
        },
        props: {
            label: String,
            placeHolder: String,
            buttonText: String,
        },
        components: {
            SkillTag
        },
        computed: {
            ...mapGetters([
                'searchSkillTagsByTitle',
                'skillTagsByType',
                'skillTagTypes',
                'skillTagsByTypeCount',
            ])
        },
        methods: {
            skillTagAdded: function() {
                this.searchString = ''
            }
        }
    }
</script>
