<template>
    <div class="container">
        <div class="row py-5">
            <div class="col-md-3">
                <Navigation :links="links" include-divider />
                <CategorySection
                    id="artwork-categories"
                    class="sticky-top flex-column pt-1"
                />
            </div>
            <div class="col" role="main">
                <ArtworkSection />
            </div>
        </div>
    </div>
</template>

<script>
    import ArtworkSection from '../components/Artwork/ArtworkSection';
    import CategorySection from '../components/Artwork/CategorySection';
    import Navigation from '../components/Navigation'

    export default {
        components: {
            ArtworkSection,
            CategorySection,
            Navigation,
        },
        data() {
            return {
                links: [
                    {
                        'address': '/',
                        'text': 'Home',
                    },
                    {
                        'address': '/cv',
                        'text': 'CV',
                    },
                ],
            };
        },
        created() {
            this.$store.dispatch('getArtworkCategories');
            this.$store.dispatch('getArtwork');
        }
    };
</script>
