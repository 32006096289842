// Module for Blog Categories.

import axios from 'axios'

// Properties available in the store for this module.
const state = {
    categories: [],
    filteredCategories: [],
};

// Getters.
const getters = {
    categories(state) {
        return state.categories
    },
    categoryById: (state) => (id) => {
        return state.categories.find(tag => tag.id === id)
    },
    filteredCategories(state) {
        return state.filteredCategories
    },
    searchCategoriesByTitle: (state) => (title) => {
        if (title) {
            return state.categories.filter(function(category) {
                if (state.filteredCategories.includes(category)){
                    return false
                } else {
                    return category.title.toLowerCase().includes(title.toLowerCase())
                }
            })
        } else {
            return []
        }
    },
};

// Actions.
const actions = {
    // Get all available Blog Categories.
    getCategories({ commit }) {
        axios.get('/api/blog/categories').then((response) => {
            commit('setCategories', response.data)
        })
    },
    addCategoryToFilter({ commit, state }, category) {
        if (!state.filteredCategories.includes(category)) {
            commit('addCategoryToFilter', category)
        }
    },
    removeCategoryFromFilter({ commit, state }, category) {
        if (state.filteredCategories.includes(category)) {
            commit('removeCategoryFromFilter', category)
        }
    },
};

// Mutations.
const mutations = {
    setCategories(state, categories) {
        state.categories = categories
    },
    addCategoryToFilter(state, category) {
        state.filteredCategories.push(category)
    },
    removeCategoryFromFilter(state, category) {
        state.filteredCategories.splice(state.filteredCategories.indexOf(category), 1)
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
