<template>
    <p class="text-light pt-2 px-1">{{ caption }}</p>
</template>

<script>
    export default {
        props: {
            caption: String,
        },
    };
</script>
