// Component for the CareerHistoryEvent section.

<template>
    <div id="careerEvents" v-if="filteredSkillTags">
        <div class="row" v-for="careerEvent in careerEventsByFilteredSkills(filteredSkillTags)">
            <CareerEvent :careerEvent="careerEvent"></CareerEvent>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import CareerEvent from './CareerEvent'

    export default {
        components: {
            CareerEvent,
        },
        computed: {
            ...mapGetters([
                'careerEventsByFilteredSkills',
                'filteredSkillTags',
            ])
        }
    }
</script>
