import Vue from 'vue'
import Vuex from 'vuex'

import artworkCategories from './modules/artwork/categories';
import artwork from './modules/artwork/artwork';
import categories from './modules/blog/categories'
import posts from './modules/blog/posts'
import careerEvents from './modules/cv/careerEvents'
import skillTags from './modules/cv/skillTags'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        artwork,
        artworkCategories,
        categories,
        careerEvents,
        posts,
        skillTags,
    }
})
