// Component for a CareerHistoryEvent.

<template>
    <div class="card w-100 px-3 mb-3 mx-3">
        <div class="card-body">
            <div class="row">
                <h4>
                    {{ careerEvent.event_title }}
                    <small>
                        {{ careerEvent.event_role }} ({{ careerEvent.event_date_range }})
                    </small>
                </h4>
            </div>
            <div class="row">
                <ul class="horizontal-list" v-if="filteredSkillTags">
                    <li v-for="skillId in careerEvent.event_skills">
                        <SkillTag
                            :skillTag="skillTagById(skillId)"
                        />
                    </li>
                </ul>
            </div>
            <div class="row" v-if="careerEvent.event_url">
                <a :href="careerEvent.event_url" target="_blank" rel="noreferrer">{{ careerEvent.event_url }}</a>
            </div>
            <br>
            <div class="row" v-html="careerEvent.event_content"></div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import SkillTag from './SkillTag'

    export default {
        components: {
            SkillTag,
        },
        props: {
            careerEvent: Object,
        },
        computed: {
            ...mapGetters([
                'filteredSkillTags',
                'skillTagById',
            ])
        }
    }
</script>
