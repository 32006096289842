<template>
    <div class="container">
        <div class="row py-5">
            <div class="col-md-3">
                <Navigation
                    :includeDivider="true"
                    :links="links"
                />
                <SkillSection
                    id="cv-skills"
                    class="sticky-top flex-column pt-1"
                />
            </div>
            <div class="col" role="main">
                <CareerEventSection></CareerEventSection>
            </div>
        </div>
    </div>
</template>

<script>
    import CareerEventSection from '../components/CV/CareerEventSection'
    import Navigation from '../components/Navigation'
    import SkillSection from '../components/CV/SkillSection'

    export default {
        data: function() {
            return {
                links: [
                    {
                        'address': '/',
                        'text': 'Home',
                    },
                    {
                        'address': '/artwork',
                        'text': 'Artwork',
                    },
                ]
            }
        },
        components: {
            CareerEventSection,
            Navigation,
            SkillSection,
        },
        created () {
            // Get the SkillTags.
            this.$store.dispatch('getSkillTags');
            this.$store.dispatch('getSkillTagTypes');

            // Get the CareerHistoryEvents.
            this.$store.dispatch('getCareerEvents');
        }
    }
</script>
