import axios from "axios";

const state = {
    artwork: [],
    selectedPiece: null,
};

const getters = {
    artworkByCategories: (state) => (categories) =>  {
        if (categories.length > 0) {
            // The filtered categories list needs to get mapped to just the IDs.
            let categoryIds = categories.map(category => category.id);

            // For each artwork piece, if there are some of the artwork's categories
            // in the list of Category IDs they will show in the results.
            return state.artwork.filter(
                artwork => artwork.categories.some(
                    categoryId => categoryIds.includes(categoryId)
                )
            );
        } else {
            // Otherwise, just return all artwork.
            return state.artwork;
        }
    },
};

const actions = {
    getArtwork({ commit }) {
        axios.get('/api/artwork').then((response) => {
            commit('setArtwork', response.data);
        })
    },
};

const mutations = {
    setArtwork(state, artwork) {
        state.artwork = artwork;
    },
    setSelectedPiece(state, selectedPiece) {
        state.selectedPiece = selectedPiece;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
