<template>
    <div class="col">
        <div class="row">
            <label for="search-string-field" class="sr-only">{{ label }}</label>
            <input
                id="search-string-field"
                class="form-control mb-2 mr-sm-2"
                type="text"
                :placeholder="placeHolder"
                v-model="searchString"
            >
        </div>
        <div class="row">
            <ul class="horizontal-list" id="search-results">
                <CategoryTag
                    v-for="categoryTag in searchArtworkCategoriesByTitle(searchString)"
                    :key="categoryTag.title"
                    @categoryTagAdded="categoryTagAdded"
                    :class="'mx-1 mb-1'"
                    :actionsAllowed="true"
                    :categoryTag="categoryTag"
                />
            </ul>
        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex';
    import CategoryTag from './CategoryTag';

    export default {
        components: {
            CategoryTag,
        },
        props: {
            label: String,
            placeHolder: String,
            buttonText: String,
        },
        data() {
            return {
                searchString: '',
            };
        },
        computed: {
            ...mapGetters(['searchArtworkCategoriesByTitle']),
        },
        methods: {
            categoryTagAdded() {
                this.searchString = '';
            },
        },
    };
</script>
