<template>
    <div class="vertical-center">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col">
                    <logo class="img-fluid"></logo>
                    <h1 class="display-1">{{ hostName }}</h1>
                    <ul class="horizontal-list">
                        <router-link tag="li" to="/artwork">
                            <a>ARTWORK</a>
                        </router-link>
                        <li>/</li>
                        <router-link tag="li" to="/cv">
                            <a>CV</a>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import logo from '../components/Logo';

    export default {
        components: {
            logo
        },
        data() {
            return { hostName: location.host };
        },
    };
</script>
