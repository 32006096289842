<template>
    <div class="btn-group" role="group">
        <button
            :class="getButtonClassByTitle(categoryTag.title)"
            :disabled="!actionsAllowed"
            type="button"
            @click="addCategoryTag(categoryTag)"
        >
            {{ categoryTag.title }}
        </button>
        <button
            v-if="removable"
            v-on:click="removeCategoryFromFilter(categoryTag)"
            type="button"
            :class="getButtonClassByTitle(categoryTag.title)"
            :aria-label="`Delete ${categoryTag.title} from filter`"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                categoryTitleCSSMapping: {
                    Animals: 'primary',
                    Drawings: 'dark',
                    Buildings: 'info',
                    Other: 'warning',
                },
            };
        },
        props: {
            actionsAllowed: Boolean,
            categoryTag: Object,
            removable: Boolean,
            solid: Boolean,
        },
        methods: {
            addCategoryTag(categoryTag) {
                if (this.actionsAllowed) {
                    this.$store.dispatch('addArtworkCategoryToFilter', categoryTag);
                    // Emit the categoryTagAdded event, used by the SearchForm
                    // component to clear the search string.
                    this.$emit('categoryTagAdded');
                }
            },
            getButtonClassByTitle(title) {
                const cssClass = this.categoryTitleCSSMapping[title] || this.categoryTitleCSSMapping.Other;
                return `btn btn-sm btn-outline-${cssClass}`;
            },
            removeCategoryFromFilter(categoryTag) {
                if (this.removable) {
                    this.$store.dispatch('removeArtworkCategoryFromFilter', categoryTag);
                }
            },
        },
    };
</script>
