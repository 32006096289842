// Component for a SkillTag.

// The v-on event binding will add the SkillTag to the filter if actions are
// allowed for this SkillTag. The SkillTag component is used in places where
// modifying the filter shouldn't be allowed.

// SkillTags that are removable will have an additional close button that will
// remove the SkillTag from the FilteredSkillTags list.
<template>
    <div class="btn-group" role="group">
        <button
            v-on:click="addSkillTag(skillTag)"
            type="button"
            :class="getButtonClassByType(skillTag.skill_type)"
            :disabled="!actionsAllowed"
        >
            {{ skillTag.title }}
        </button>
        <button
            v-if="removable"
            v-on:click="removeSkillFromFilter(skillTag)"
            type="button"
            :class="getButtonClassByType(skillTag.skill_type)"
            :aria-label="`Delete ${skillTag.title} from filter`"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                skillTypeCSSMapping: {
                    'programming': 'primary',
                    'source_control': 'danger',
                    'software': 'dark',
                    'operating_systems': 'info',
                    'soft_skills': 'success',
                    'other': 'warning'
                },
                baseClass: 'btn btn-sm'
            }
        },
        props: {
            actionsAllowed: Boolean,
            removable: Boolean,
            skillTag: Object,
            solid: Boolean,
        },
        methods: {
            addSkillTag: function(skillTag) {
                if (this.actionsAllowed) {
                    // Call the 'addSkillTagToFilter' action to add the SkillTag.
                    this.$store.dispatch('addSkillTagToFilter', skillTag);
                    // Emit the skillTagAdded event, used by the SearchForm
                    // component to clear the search string.
                    this.$emit('skillTagAdded')
                }
            },
            getButtonClassByType: function(type) {
                // Get the Button class from the mapping.
                return `${this.baseClass} btn-outline-${this.skillTypeCSSMapping[type]}`
            },
            removeSkillFromFilter: function(skillTag) {
                // Remove the SkillTag from the filteredTags list via the
                // mutation. This will update the filter for CareerEvents.
                if (this.removable) {
                    this.$store.dispatch('removeSkillTagFromFilter', skillTag);
                }
            }
        }
    }
</script>
