// Module for Blog Posts.

import axios from 'axios'

// Properties available in the store for this module.
const state = {
    posts: [],
    post: null,
};

// Getters.
const getters = {
    getPost(state) {
        return state.post
    },
    posts(state) {
        return state.posts
    },
    postsByCategories: (state) => (categories) =>  {
        if (categories.length > 0) {
            // The filtered categories list needs to get mapped to just the IDs.
            let categoryIds = categories.map(category => category.id);

            // For each post, if there are some of the post's categories
            // in the list of Category IDs they will show in the results.
            return state.posts.filter(
                post => post.categories.some(
                    categoryId => categoryIds.includes(categoryId)
                )
            )
        } else {
            // Otherwise, just return all posts.
            return state.posts
        }
    },
};

// Actions.
const actions = {
    // Get all available Blog Posts.
    getPosts({ commit }) {
        axios.get('/api/blog/posts').then((response) => {
            commit('setPosts', response.data)
        })
    },
    getPostBySlug({ commit }, params) {
        axios.get('/api/blog/posts/' + params.slug).then((response) => {
            commit('setPost', response.data)
        }).catch(error => {
            params.router.push('/blog');
        })
    },
};

// Mutations.
const mutations = {
    setPosts(state, posts) {
        state.posts = posts
    },
    setPost(state, post) {
        state.post = post
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
