// Filters available to all Components.

import _startCase from "lodash/startCase";
import Vue from "vue"
import moment from 'moment'

// Convert strings from "start_case" or "startCase" to "Start case".
Vue.filter('toStartCase', function(value) {
    return _startCase(value)
});

// Filter to format date time strings using moment.js, the formatString is optional.
Vue.filter('formatDateTime', function(dateString, formatString) {
    return moment(dateString).format(formatString ? formatString : 'YYYY-MM-DD HH:MM');
});
