<template>
    <div id="active-skills">
        <SkillTagSearchForm
            :label="'Search Skills to Filter by'"
            :placeHolder="'Filter CV'"
            :buttonText="'Add Skill'"
        />
        <div v-if="filteredSkillTagsByTypeCount(skill_type) > 0" v-for="skill_type in skillTagTypes">
            <span>{{ skill_type | toStartCase }}</span>
            <ul class="horizontal-list">
                <li v-for="skillTag in filteredSkillTagsByType(skill_type)">
                    <SkillTag
                        :skillTag="skillTag"
                        :removable="true"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import SkillTag from './SkillTag'
    import SkillTagSearchForm from './SkillTagSearchForm'

    export default {
        data: function() {
            return {
                searchString: '',
            }
        },
        components: {
            SkillTagSearchForm,
            SkillTag
        },
        computed: {
            ...mapGetters([
                'filteredSkillTags',
                'filteredSkillTagsByType',
                'filteredSkillTagsByTypeCount',
                'skillTags',
                'skillTagsByType',
                'skillTagsByTypeCount',
                'skillTagTypes',
            ])
        }
    }
</script>
