<template>
    <div class="nav-menu">
        <ul v-bind:id="horizontal ? `` : `main-menu`" class="nav">
            <router-link tag="li" :to="link.address" v-for="link in links" :key="link.text">
                <a class="nav-link">{{ link.text }}</a>
            </router-link>
        </ul>
        <hr v-if="includeDivider">
    </div>
</template>

<script>
    export default {
        props: {
            horizontal: Boolean,
            includeDivider: Boolean,
            links: Array,
        }
    }
</script>
