<template>
    <div v-if="filteredArtworkCategories" id="artwork-pieces">
        <div class="row">
            <b-col
                v-for="(artworkPiece, index) in artworkByCategories(filteredArtworkCategories)"
                :key="index"
                xs="12"
                md="6"
                lg="4"
                class="mb-4"
            >
                <ArtworkPiece :artwork="artworkPiece" />
            </b-col>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import ArtworkPiece from './ArtworkPiece';

    export default {
        components: {
            ArtworkPiece,
        },
        computed: {
            ...mapGetters([
                'artworkByCategories',
                'filteredArtworkCategories',
            ]),
        },
    };
</script>
