<template>
    <svg viewBox="0 0 261.822 167.36" height="632.542" width="989.556">
        <g fill-rule="evenodd">
            <path fill="#353564" d="M24.385 47.983v47.403H71.76V47.983z" />
            <path fill="#afafde" d="M24.25 95.773L.494 119.663H47.6l23.753-23.89z" />
            <path fill="#8686bf" d="M24.385 47.983L.495 72.251v47.403l23.89-24.268z" />
            <path transform="matrix(.0049 0 0 .00498 -55.51 185.18)" stroke-width="202.46" stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M25970-27560l-4874.9 4874.9v9522.3l4874.9-4874.9z" />
            <path transform="matrix(.0049 0 0 .00498 -55.51 185.18)" stroke-width="202.46" stroke-linejoin="round" stroke="#000" fill="#fff" d="M11428-22685v9522.3h9667.2V-22685z" />
        </g>

        <path
            stroke-linejoin="round"
            fill-rule="evenodd"
            d="M47.932 72.17l23.89-23.89H24.447L.557 72.17z"
            stroke="#000"
            @mouseover="mouseEnter($event, 'middle-left')"
            @mouseout="mouseLeave($event, 'middle-left')"
        />

        <g fill-rule="evenodd">
            <path fill="#353564" d="M48.274 72.139v46.664H95.65V72.14z" />
            <path fill="#afafde" d="M48.274 118.803l-23.89 23.89H71.76l23.89-23.89z" />
            <path fill="#8686bf" d="M48.274 72.139l-23.89 23.89v46.664l23.89-23.89z" />
            <path stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M95.65 72.139l-23.89 23.89v46.664l23.89-23.89z" />
            <path stroke-linejoin="round" stroke="#000" fill="#fff" d="M24.385 96.029v46.664H71.76V96.029z" />
        </g>

        <path
            stroke-linejoin="round"
            fill-rule="evenodd"
            d="M48.276 72.157l-23.89 23.89h47.375l23.89-23.89z"
            stroke="#000"
            @mouseover="mouseEnter($event, 'bottom-middle-left')"
            @mouseout="mouseLeave($event, 'bottom-middle-left')"
        />

        <g fill-rule="evenodd">
            <path fill="#afafde" d="M72.166 141.599l-23.89 23.89H95.65l23.89-23.89z" />
            <path stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M119.54 94.939l-23.89 23.89v46.665l23.89-23.89z" />
            <path stroke-linejoin="round" stroke="#000" fill="#fff" d="M48.275 118.829v46.665H95.65v-46.665z" />
        </g>

        <path
            transform="matrix(.98928 0 0 .95031 9.936 -59.612)"
            stroke-linejoin="round"
            fill-rule="evenodd"
            d="M62.645 163.88l-23.89 23.89H86.13l23.89-23.89z"
            stroke="#000"
            @mouseover="mouseEnter($event, 'bottom')"
            @mouseout="mouseLeave($event, 'bottom')"
        />

        <g fill-rule="evenodd">
            <path fill="#353564" d="M166.974.95v45.738h47.376V.95z" />
            <path fill="#afafde" d="M166.974 46.688l-23.89 23.415h47.376l23.89-23.415z" />
            <path fill="#8686bf" d="M166.974.95l-23.89 23.416v45.737l23.89-23.415z" />
            <path transform="matrix(.01852 0 0 .01815 86.88 129.888)" stroke-width="54.534" stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M6882.1-7102.4L5592.3-5812.6v2519.4L6882.1-4583z" />
            <path transform="matrix(.01852 0 0 .01815 86.88 129.888)" stroke-width="54.534" stroke-linejoin="round" stroke="#000" fill="#fff" d="M3034.5-5812.6v2519.4h2557.8v-2519.4z" />
        </g>

        <path
            stroke-linejoin="round"
            fill-rule="evenodd"
            d="M214.351.499h-47.375l-23.89 23.89h47.375z"
            stroke="#000"
            @mouseover="mouseEnter($event, 'top')"
            @mouseout="mouseLeave($event, 'top')"
        />

        <g fill-rule="evenodd">
            <path fill="#353564" d="M190.46 24.84v45.738h47.376V24.84z" />
            <path fill="#afafde" d="M190.46 70.578l-23.89 23.415h47.376l23.89-23.415z" />
            <path fill="#8686bf" d="M190.46 24.84l-23.89 23.416v45.737l23.89-23.415z" />
            <path transform="matrix(.01852 0 0 .01815 110.365 153.778)" stroke-width="54.534" stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M6882.1-7102.4L5592.3-5812.6v2519.4L6882.1-4583z" />
            <path transform="matrix(.01852 0 0 .01815 110.365 153.778)" stroke-width="54.534" stroke-linejoin="round" stroke="#000" fill="#fff" d="M3034.5-5812.6v2519.4h2557.8v-2519.4z" />
        </g>

        <path
            stroke-linejoin="round"
            fill-rule="evenodd"
            d="M190.462 24.389l-23.89 23.89h47.375l23.89-23.89z"
            stroke="#000"
            @mouseover="mouseEnter($event, 'top-middle')"
            @mouseout="mouseLeave($event, 'top-middle')"
        />

        <g fill-rule="evenodd">
            <path fill="#353564" d="M142.665 72.004v46.374h47.376V72.004z" />
            <path fill="#afafde" d="M142.665 118.38l-23.89 23.741h47.376l23.89-23.742z" />
            <path fill="#8686bf" d="M142.665 72.004l-23.89 23.742v46.374l23.89-23.742z" />
            <path transform="matrix(.26458 0 0 .26294 85.934 138.52)" stroke-width="3.791" stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M393.48-252.97l-90.294 90.294v176.37L393.48-76.6z" />
            <path transform="matrix(.26458 0 0 .26294 85.934 138.52)" stroke-width="3.791" stroke-linejoin="round" stroke="#000" fill="#fff" d="M124.13-162.68V13.69h179.06v-176.37z" />
        </g>

        <path
            stroke-linejoin="round"
            fill-rule="evenodd"
            d="M142.682 72.17l-23.89 23.89h47.375l23.89-23.89z"
            stroke="#000"
            @mouseover="mouseEnter($event, 'bottom-middle-right')"
            @mouseout="mouseLeave($event, 'bottom-middle-right')"
        />

        <g fill-rule="evenodd">
            <path fill="#353564" d="M213.716 48.79v46.473h47.375V48.791z" />
            <path fill="#afafde" d="M213.716 95.263l-23.89 23.792H237.2l23.89-23.792z" />
            <path fill="#8686bf" d="M213.716 48.79l-23.89 23.792v46.473l23.89-23.792z" />
            <path transform="matrix(.07 0 0 .06972 141.408 165.37)" stroke-width="14.314" stroke-linejoin="round" stroke="#000" fill="#e9e9ff" d="M1709.6-1672.2l-341.27 341.27v666.6l341.27-341.27z" />
            <path transform="matrix(.07 0 0 .06972 141.408 165.37)" stroke-width="14.314" stroke-linejoin="round" stroke="#000" fill="#fff" d="M691.61-1330.9v666.6h676.75v-666.6z" />

            <path
                stroke-linejoin="round"
                d="M213.947 48.28l-23.89 23.89h47.375l23.89-23.89z"
                stroke="#000"
                @mouseover="mouseEnter($event, 'middle-right')"
                @mouseout="mouseLeave($event, 'middle-right')"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        methods: {
            mouseEnter: function(event, colour) {
                event.target.classList.add(colour + '-fill');
            },
            mouseLeave: function(event, colour) {
                event.target.classList.remove(colour + '-fill');
            }
        }
    };
</script>

<style lang="scss" scoped>
    svg {
        max-height: 500px;
    }
</style>
