// Routes for ulternate.com.

// Import views.
import Index from '../views/Index';
import Artwork from '../views/Artwork';
import CV from '../views/CV';

// Routes for the application.
const routes = [
    {
        path: '/',
        name: 'home',
        component: Index
    },
    {
        path: '/artwork',
        name: 'artwork',
        component: Artwork,
    },
    {
        path: '/cv',
        name: 'cv',
        component: CV
    },
    {
        path: '*',
        component: Index
    },
];

export default routes;
